import React from "react";
import KurdishSymbol from "./components/symbol";

import "./App.scss";

export function App() {
  return (
    <main className="app">
      <div className="splash">
        <KurdishSymbol index={1} />
        <div className="title">
          <h1>Samal Studios</h1>
          <small>Dahênan - Berz - Avahî</small>
        </div>
      </div>
    </main>
  );
}
